import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShortSpanishDate } from "../../functions/date.function";

import "./blog-card.component.scss";
import { navigate } from "gatsby";

export const BlogCardComponent = ({cardData, path }: {cardData: any, path: string }) => {

  return (
    <div className="blog-card" onClick={() => {navigate("/blog" + cardData.fields.slug + "/")}}>
      <GatsbyImage
        className="blog-card-thumbnail"
        image={cardData.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        alt={"Imagen sobre " + cardData.title}
      />

      <div className="blog-card-container">
        <div className="blog-card-content-background"></div>
        <div className="blog-card-content">

          <label className="blog-card-date">{getShortSpanishDate(cardData.frontmatter.date)}</label>

          <div className="blog-card-description">
            <div className="blog-card-title">
              <h2 className="blog-title"><b>{cardData.frontmatter.title}</b></h2>
            </div>
            <p>{cardData.frontmatter.description}</p>
          </div>

        </div>
      </div>
    </div>
  );
};
