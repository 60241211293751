import { graphql, navigate, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { BlogCardComponent } from "../../components/blog-card/blog-card.component";
import "./our-blogs.section.scss";

export const OurBlogsSection = ({path, data}: {path: string; data: any}) => {

  const getLastBlogsCads = (getAllBlogsData: any) => {
    const blogArray: any[] = [];
    getAllBlogsData.forEach(
      (blogData: any) => {
        blogArray.push(
          <BlogCardComponent key={blogData.node.id} cardData={blogData.node} path={path}/>
        );
      }
    );
    return blogArray;
  }

  return (
    <>
      <div className="blogs-section">
        <div className="blogs-header-section">
          <div className="title-container">
            <StaticImage
              className="title-background"
              src="../../assets/images/title-background-black.png"
              alt="Imagen de fondo del título para Blog de Onixdev"
              placeholder="tracedSVG"
            />
            <h2 className="blog-title">Blogs</h2>  
          </div>

          <h3 className="blog-subtitle">Descubre nuestros último blogs</h3>
          <p className="blog-description">
            Blogs elaborados por nuestro equipo de trabajo con el objetivo de ayudar con información de valor sobre tecnología.
          </p>
        </div>

        <div className="blogs-cards">
          {
            getLastBlogsCads(data.edges)
          }
        </div>

        {
          path === "home" ? 
          <div className="blogs-buttons">
            <button className="button" onClick={() => { navigate("/blog") }}>Ver más</button>
          </div>
          : <></>
        }
      </div>
    </>
  );
};
