import * as React from "react";
import { graphql } from "gatsby";
import { MainLayout } from "../layout/main/main.layout";
import { OurBlogsSection } from "../section/our-blogs/our-blogs.section";
import { SeoComponent } from "../components/seo/seo.component";

export const Head = ({data}: {data: any}) => {
  const { siteMetadata } = data.site;
  const pageData = {
    title: "Onixdev Blog | Los mejores blogs de tecnología e ingeniería de software",
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.url,
    author: siteMetadata.author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: siteMetadata.image,
    description: siteMetadata.description,
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  }

  return (
    <>
      <meta name="resource-type" content="Homepage"></meta>
      <SeoComponent {...pageData}/>
    </>
  );
};

const BlogPage = ({ data }: { data: any; }) => {
  return (
    <MainLayout siteMetaData={data.site.siteMetadata} path="blog">

      <section id="our-blogs">
        <OurBlogsSection data={data.allMdx} path=""/>
      </section>
    </MainLayout>
  );
};

export default BlogPage;

export const query = graphql`
  query SiteMetaDataBlog {
    site {
      siteMetadata {
        author
        description
        image
        menuLinks {
          link
          name
        }
        siteUrl
        title
        url
        lang
        charSet
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges{
        node {
          id
          frontmatter {
            date
            description
            title,
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`