export const getSpanishDate = (_date: Date) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const weekdays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const date = new Date(_date);
    return weekdays[date.getDay()] + ', ' + date.getDate() + ' de ' + months[date.getMonth()] + ' del ' + date.getUTCFullYear();
}

export const getShortSpanishDate = (_date: Date) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const date = new Date(_date);
    return  months[date.getMonth()] + ', ' + date.getDate() + ' del ' + date.getUTCFullYear();
}

export const getNumbersDate = (_date: Date) => {
    const date = new Date(_date);
    return  date.getMonth() + '/' + date.getDate() + '/' + date.getUTCFullYear();
}