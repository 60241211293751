import React from 'react';
import { SiteMetaDataInterface } from '../../interface/site.metadata.interface';

export const SeoComponent = (siteMetaData: SiteMetaDataInterface ) => {
  return (
    <>
      <meta charSet={siteMetaData.charSet} />
      <title>{siteMetaData.title}</title>
      <meta name="title" content={siteMetaData.title}></meta>

      {/* <!-- HTML Meta Tags --> */}
      <meta name="description" content={siteMetaData.description} />
      <meta name="keywords" content="Onixdev,Onixdev Technology,Quito,Ecuador,Tecnología,Desarrollo de Software,Páginas Web,Aplicaciones Web,Ágil,Software,Apps,Móvil"></meta>
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      
      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={siteMetaData.title} />
      <meta itemProp="image" content={siteMetaData.image} />
      
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={siteMetaData.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteMetaData.title} />
      <meta property="og:description" content={siteMetaData.description} />
      <meta property="og:image" content={siteMetaData.image} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteMetaData.title} />
      <meta name="twitter:description" content={siteMetaData.description} />
      <meta name="twitter:image" content={siteMetaData.image} />

      <meta name="copyright" content="Copyright ©2022 Onixdev"></meta>
      <meta name="revisit-after" content="1 days"></meta>
      {/* BING */}
      <meta name="msnbot" content={siteMetaData.description}></meta>
    </>
  )
}
